import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PH2, PText, PBox, PHighlightButton } from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { MailOutlined } from "@ant-design/icons";

import { Input, Button, Form } from "antd";
import { userForgotPassword } from "../../../actions/auth";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    onFinish = (values) => {
        console.log("Success:", values);
        this.props.userForgotPassword(values, this.props.history);
    };
    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="forgot-password">
                    <PBox className="forgot-password-title">
                        <PH2>Forgot Password</PH2>
                        <PText>Re-Password with Phair.</PText>
                    </PBox>
                    <PBox className="forgot-password-notice">
                        <PText>
                            Enter your Email and instructions will be sent to
                            you.
                        </PText>
                    </PBox>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 0,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: "100%",
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    message: "${label} is not a valid email.",
                                },
                                {
                                    required: true,
                                    message: "Please enter your email.",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Email"
                                prefix={<MailOutlined />}
                            />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <PHighlightButton
                                type="primary"
                                htmlType="submit"
                                size="large"
                                className="forgot-password-send-request-button"
                            >
                                Send Request
                            </PHighlightButton>
                        </Form.Item>
                    </Form>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    minHeight: "400px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
            cursor: "pointer",
        },
    },
    "& .forgot-password-send-request-button": {
        backgroundColor: "$colors$primary",
        color: "#fff",
        width: "100%",
        marginTop: "10px",
    },
    "& .forgot-password-title": {
        marginBottom: "40px",
    },
    "& .forgot-password-notice": {
        backgroundColor: "#e2f5ed",
        border: "1px solid #c5eada",
        width: "100%",
        borderRadius: "4px",
        marginBottom: "20px",
        textAlign: "center",
        padding: "4px 0",
    },
};

export default connect(null, { userForgotPassword })(
    withRouter(ForgotPassword)
);
