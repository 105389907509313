import { combineReducers } from "redux";

const userAuth = (state = {}, action) => {
    switch (action.type) {
        case "SIGN_IN_SUCCESS":
            return {
                isLoggedIn: true,
                userAccount: action.payload,
            };
        case "SIGN_IN_FAIL":
            return {
                isLoggedIn: false,
                userAccount: null,
            };
        case "SIGN_UP_SUCCESS":
            return {
                isLoggedIn: false,
                userAccount: action.payload,
            };
        case "SIGN_UP_FAIL":
            return {
                isLoggedIn: false,
                userAccount: action.payload,
            };
        case "SIGN_OUT_SUCCESS":
            return {
                isLoggedIn: false,
                userAccount: null,
            };
        case "CREATE_MERCHANT_SUCCESS":
            return {
                isLoggedIn: state.isLoggedIn,
                userAccount: { ...state.userAccount, ...action.payload },
            };
        default:
            return state;
    }
};

const verifyToken = (state = {}, action) => {
    switch (action.type) {
        case "VERIFY_TOKEN_SUCCESS":
            return { userEmail: action.payload };
        default:
            return state;
    }
};

const fetchUsersInfo = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_USERS_INFO_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                info: action.payload,
            };
        case "FETCH_USERS_INFO_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                info: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({ userAuth, verifyToken, fetchUsersInfo });
