import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PH2, PLink, PText, PBox } from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { resendResetPassword } from "../../../actions/auth";

class ForgotPasswordConfirm extends Component {
    componentDidMount() {}
    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="forgot-password-confirm">
                    <PBox className="forgot-password-confirm-title">
                        <PBox>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-96,85.15L52.57,64H203.43ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z"></path>
                            </svg>
                        </PBox>
                        <PH2>Password Reset Email Sent</PH2>
                        <PText>
                            Please click the link in email to reset your
                            password.
                        </PText>
                        <PText>
                            If you do not receive the email within a few
                            moments, please check your spam folder or other
                            filtering tools.
                        </PText>
                    </PBox>
                    <PText>
                        Didn't receive an email?{" "}
                        <PLink
                            onClick={() => {
                                this.props.resendResetPassword(
                                    this.props.userEmail?.email
                                );
                            }}
                        >
                            Resend
                        </PLink>
                    </PText>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    minHeight: "400px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
            cursor: "pointer",
        },
    },
    "& .forgot-password-confirm-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "100px",
            fill: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {
        userEmail: state.authReducer?.verifyToken?.userEmail,
    };
}
export default connect(mapStateToProps, { resendResetPassword })(
    withRouter(ForgotPasswordConfirm)
);
