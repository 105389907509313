import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { resendVerification } from "../../../actions/auth";

class EmailNotVerified extends Component {
    componentDidMount() {}
    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="email-not-verified">
                    <PBox className="email-not-verified-title">
                        <PBox>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M120,136V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0ZM232,91.55v72.9a15.86,15.86,0,0,1-4.69,11.31l-51.55,51.55A15.86,15.86,0,0,1,164.45,232H91.55a15.86,15.86,0,0,1-11.31-4.69L28.69,175.76A15.86,15.86,0,0,1,24,164.45V91.55a15.86,15.86,0,0,1,4.69-11.31L80.24,28.69A15.86,15.86,0,0,1,91.55,24h72.9a15.86,15.86,0,0,1,11.31,4.69l51.55,51.55A15.86,15.86,0,0,1,232,91.55Zm-16,0L164.45,40H91.55L40,91.55v72.9L91.55,216h72.9L216,164.45ZM128,160a12,12,0,1,0,12,12A12,12,0,0,0,128,160Z"></path>
                            </svg>
                        </PBox>
                        <PH2>Please Verifiy Your Email</PH2>
                        <PText>
                            We have sent an email to{" "}
                            <b>{this.props.userEmail?.email}</b>. You need to
                            verify your email to continue. <br /> <br />
                            If you have not received the email, please check
                            your "Spam" or "Bulk Email" folder. You can also
                            click the resend button below to have another email
                            sent to you.
                        </PText>
                    </PBox>
                    <PHighlightButton
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="email-not-verified-button"
                        onClick={() => {
                            this.props.resendVerification(
                                this.props.userEmail?.email
                            );
                        }}
                    >
                        Resend
                    </PHighlightButton>
                    <PText>
                        Already confirmed your email?{" "}
                        <PLink href="/sign-in">Login</PLink>
                    </PText>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    minHeight: "400px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$quinary",
        "&:hover": {
            color: "#000",
        },
    },
    "& .email-not-verified-button": {
        backgroundColor: "$colors$quinary",
        width: "100%",
        margin: "20px 0",
        color: "#fff",
    },
    "& .email-not-verified-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "100px",
            fill: "$colors$quinary",
        },
    },
};

function mapStateToProps(state) {
    return {
        userEmail: state.authReducer?.verifyToken?.userEmail,
    };
}
export default connect(mapStateToProps, { resendVerification })(
    withRouter(EmailNotVerified)
);
