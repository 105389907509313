import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import SignIn from "./views/pages/authentication/SignIn";
import SignUp from "./views/pages/authentication/SignUp";
import SignOut from "./views/pages/authentication/SignOut";
import ResetPassword from "./views/pages/authentication/ResetPassword";
import ForgotPassword from "./views/pages/authentication/ForgotPassword";
import ForgotPasswordConfirm from "./views/pages/authentication/ForgotPasswordConfirm";
import RegistrationSuccess from "./views/pages/authentication/RegistrationSuccess";
import EmailNotVerified from "./views/pages/authentication/EmailNotVerified";
import EmailConfirmation from "./views/pages/authentication/EmailConfirmation";
import SessionExpired from "./views/pages/authentication/SessionExpired";
import PendingApprovalList from "./views/pages/admin/PendingApprovalList";
import ApproveProduct from "./views/pages/admin/ApproveProduct";
import Home from "./views/pages/admin/Home";
import CategoryManagement from "./views/pages/admin/CategoryManagement";
import OrderManagement from "./views/pages/admin/OrderManagement";
import MerchantManagement from "./views/pages/admin/MerchantManagement";
import ProductManagement from "./views/pages/admin/ProductManagement";
import UserManagement from "./views/pages/admin/UserManagement";

const AdminRoute = (props) => {
    return props.isLoggedIn && props.isAdmin ? (
        <Route {...props} />
    ) : (
        <Redirect to="/sign-in" />
    );
};

const AppRoutes = (props) => {
    return (
        <Switch>
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={Home}
                exact
                path="/"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={PendingApprovalList}
                exact
                path="/all-pending-approval"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={ApproveProduct}
                exact
                path="/approve-product/:product_uuid"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={CategoryManagement}
                exact
                path="/category-management"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={OrderManagement}
                exact
                path="/order-management"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={MerchantManagement}
                exact
                path="/merchant-management"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={ProductManagement}
                exact
                path="/product-management"
            />
            <Route
                isLoggedIn={props.currentUser?.isLoggedIn}
                isAdmin={props.currentUser?.userAccount?.is_admin}
                component={UserManagement}
                exact
                path="/user-management"
            />
            {/* Authentication Pages */}
            <Route component={SignIn} exact path="/sign-in" />
            <Route component={SignUp} exact path="/sign-up" />
            <Route component={SignOut} exact path="/sign-out" />
            <Route component={SessionExpired} exact path="/session-expired" />
            <Route
                component={RegistrationSuccess}
                exact
                path="/registration-success"
            />
            <Route
                component={EmailNotVerified}
                exact
                path="/email-not-verified"
            />
            <Route component={ForgotPassword} exact path="/forgot-password" />
            <Route
                component={ResetPassword}
                exact
                path="/reset-password/:token"
            />
            <Route
                component={ForgotPasswordConfirm}
                exact
                path="/forgot-password-confirm"
            />
            <Route
                component={EmailConfirmation}
                exact
                path="/email-confirmation/:token"
            />
        </Switch>
    );
};

export default AppRoutes;
