import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PContainer, PSection, PBox, PH1, PText } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Pagination } from "antd";
import { fetchUsersInfo } from "../../../actions/auth";

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 1,
            pageSize: 10,
        };
    }

    async componentDidMount() {
        await this.props.fetchUsersInfo(
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="user-management">
                            <PH1>User Management</PH1>
                            {this.props.users?.map((user, idx) => {
                                return (
                                    <PBox className="user-management-user-item">
                                        <PText>
                                            <b>
                                                {user.id}. {user.user_name}
                                            </b>{" "}
                                        </PText>
                                        <PText>
                                            <b>Is Seller: </b>
                                            {user.is_seller ? "True" : "False"}
                                        </PText>
                                        <PText>
                                            <b>Stripe Account Id: </b>
                                            {user?.seller?.stripe_account_id}
                                        </PText>
                                        <PText>
                                            <b>Email: </b>
                                            {user?.email}
                                        </PText>
                                        <PText>
                                            <b>Is Verified: </b>
                                            {user?.is_verified
                                                ? "True"
                                                : "False"}
                                        </PText>
                                    </PBox>
                                );
                            })}
                            <Pagination
                                pageSize={this.state.pageSize}
                                current={this.state.page}
                                total={this.props.total}
                                onChange={async (pageNumber) => {
                                    await this.setState({ page: pageNumber });
                                    await this.props.fetchUsersInfo(
                                        (this.state.page - 1) *
                                            this.state.pageSize,
                                        this.state.pageSize
                                    );
                                }}
                            />
                        </PBox>
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .user-management-user-item": {
        borderBottom: "1px solid $colors$borderColor",
        padding: "20px",
    },
};

function mapStateToProps(state) {
    return {
        users: state.authReducer?.fetchUsersInfo?.info?.userList,
        total: state.authReducer?.fetchUsersInfo?.info?.totalUsers,
    };
}

export default connect(mapStateToProps, { fetchUsersInfo })(
    withRouter(UserManagement)
);
