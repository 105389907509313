import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import {
    PContainer,
    PH1,
    PSection,
    PHighlightLink,
    PBox,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="management-board">
                            <PHighlightLink href="/all-pending-approval">
                                All Pending Approval Products
                            </PHighlightLink>
                            <PHighlightLink href="/category-management">
                                Category Management
                            </PHighlightLink>
                            <PHighlightLink href="/order-management">
                                Order Management
                            </PHighlightLink>
                            <PHighlightLink href="/merchant-management">
                                Merchant Management
                            </PHighlightLink>
                            <PHighlightLink href="/product-management">
                                Product Management
                            </PHighlightLink>
                            <PHighlightLink href="/user-management">
                                User Management
                            </PHighlightLink>
                        </PBox>
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .management-board": {
        display: "grid",
        gridGap: "20px",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp2": {
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "@bp4": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        },
        "@bp6": {
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        "& a": {
            textAlign: "center",
            backgroundColor: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(Home));
