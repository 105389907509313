import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
    PHighlightLink,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { confirmEmail } from "../../../actions/auth";
import { Loader } from "../../components/Loader";

class EmailConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .confirmEmail(this.props.match.params.token, this.props.history)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="email-confirmation">
                    <PBox className="email-confirmation-title">
                        <PBox>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                            </svg>
                        </PBox>
                        <PH2>Verification Success</PH2>
                        <PText>
                            Thank you. Please log in to your account to
                            continue.
                        </PText>
                    </PBox>
                    <PHighlightButton
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="email-confirmation-button"
                        onClick={() => {
                            this.props.history.push("/sign-in");
                        }}
                    >
                        Log In
                    </PHighlightButton>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    minHeight: "400px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
        },
    },
    "& .email-confirmation-button": {
        backgroundColor: "$colors$quaternary",
        width: "100%",
        margin: "20px 0",
        color: "#fff",
    },
    "& .email-confirmation-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "100px",
            fill: "$colors$quaternary",
        },
    },
};

function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, { confirmEmail })(
    withRouter(EmailConfirmation)
);
