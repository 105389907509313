import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PContainer, PSection, PBox, PH1, PText } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { fetchMerchantsInfo } from "../../../actions/merchant";
import { Pagination } from "antd";

class MerchantManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 1,
            pageSize: 10,
        };
    }

    async componentDidMount() {
        await this.props.fetchMerchantsInfo(
            this.state.page,
            this.state.pageSize
        );
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="merchant-management">
                            <PH1>Merchant Management</PH1>
                            {this.props.merchants?.map((merchant, idx) => {
                                return (
                                    <PBox className="merchant-management-merchant-item">
                                        <PText>
                                            <b>
                                                {merchant.id}.{" "}
                                                {merchant.shop_name}
                                            </b>{" "}
                                            {merchant.shop_description}
                                        </PText>
                                        <PText>
                                            <b>Beginner Todos Finished:</b>{" "}
                                            {merchant.beginner_todos_finished
                                                ? "True"
                                                : "False"}
                                        </PText>
                                        <PText>
                                            <b>
                                                Beginner Create Product
                                                Finished:
                                            </b>{" "}
                                            {merchant.beginner_create_product_finished
                                                ? "True"
                                                : "False"}
                                        </PText>
                                        <PText>
                                            <b>
                                                Beginner Connect Stripe
                                                Finished:
                                            </b>{" "}
                                            {merchant.beginner_connect_stripe_finished
                                                ? "True"
                                                : "False"}
                                        </PText>
                                        <PText>
                                            <b>
                                                Beginner Make Product Public
                                                Finished:
                                            </b>{" "}
                                            {merchant.beginner_make_product_public_finished
                                                ? "True"
                                                : "False"}
                                        </PText>
                                        <PText>
                                            <b>Sell Categories:</b>{" "}
                                            {merchant.sell_categories}
                                        </PText>
                                        <PText>
                                            <b>Shop Announcement:</b>{" "}
                                            {merchant.shop_announcement}
                                        </PText>
                                    </PBox>
                                );
                            })}
                            <Pagination
                                pageSize={this.state.pageSize}
                                current={this.state.page}
                                total={this.props.total}
                                onChange={async (pageNumber) => {
                                    await this.setState({ page: pageNumber });
                                    await this.props.fetchMerchantsInfo(
                                        this.state.page,
                                        this.state.pageSize
                                    );
                                }}
                            />
                        </PBox>
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .merchant-management-merchant-item": {
        borderBottom: "1px solid $colors$borderColor",
        padding: "20px",
    },
};

function mapStateToProps(state) {
    return {
        merchants: state.merchantReducer?.fetchMerchantsInfo?.info?.merchants,
        total: state.merchantReducer?.fetchMerchantsInfo?.info?.total,
    };
}

export default connect(mapStateToProps, { fetchMerchantsInfo })(
    withRouter(MerchantManagement)
);
