import { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Logo } from "../components/Logo";
import { PBox, PContainer, PSection, PHighlightLink } from "../theme/BaseTheme";
import { Badge, Dropdown } from "antd";
import Sticky from "react-sticky-el";
import { userSignOut } from "../../actions/auth";

class Header extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <PSection css={styles}>
                <Sticky>
                    <PBox className="header">
                        <Logo />
                        <PBox className="search-bar">
                            {/*<PInput
                                id="global-search"
                                type="text"
                                placeholder="Search for anything"
                            />
                            <PButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                                </svg>
        </PButton>*/}
                        </PBox>
                        {!this.props.userAuth?.isLoggedIn && (
                            <PHighlightLink className="sign-in" href="/sign-in">
                                Sign in
                            </PHighlightLink>
                        )}
                        {!this.props.userAuth?.userAccount?.is_seller && (
                            <PHighlightLink
                                className="start-selling"
                                href="/sell"
                            >
                                Start Selling
                            </PHighlightLink>
                        )}
                        {this.props.userAuth?.userAccount?.is_seller && (
                            <Link
                                to={`/seller/${this.props.userAuth?.userAccount?.shop_uuid}/dashboard`}
                            >
                                <PBox className="shop-dashboard">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M232,96a7.89,7.89,0,0,0-.3-2.2L217.35,43.6A16.07,16.07,0,0,0,202,32H54A16.07,16.07,0,0,0,38.65,43.6L24.31,93.8A7.89,7.89,0,0,0,24,96v16a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32ZM54,48H202l11.42,40H42.61Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-48,0v-8ZM200,208H56V151.2a40.57,40.57,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,40.57,40.57,0,0,0,8-.8Zm-8-72a24,24,0,0,1-24-24v-8h48v8A24,24,0,0,1,192,136Z"></path>
                                    </svg>
                                </PBox>
                            </Link>
                        )}
                        {this.props.userAuth?.isLoggedIn && (
                            <PBox className="account">
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <a
                                                        href={`/buyer/${this.props.userAuth?.userAccount?.uuid}/order-history`}
                                                    >
                                                        My Orders
                                                    </a>
                                                ),
                                                key: "orders",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/buyer/${this.props.userAuth?.userAccount?.uuid}/profile`}
                                                    >
                                                        Profile
                                                    </a>
                                                ),
                                                key: "profile",
                                            },
                                            {
                                                label: "Setting",
                                                key: "setting",
                                            },
                                            { type: "divider" },
                                            {
                                                label: "Open a Shop",
                                                key: "open",
                                            },
                                            { type: "divider" },
                                            {
                                                label: (
                                                    <PBox
                                                        onClick={() => {
                                                            this.props
                                                                .userSignOut()
                                                                .then(() => {
                                                                    this.props.history.push(
                                                                        "/sign-out"
                                                                    );
                                                                });
                                                        }}
                                                    >
                                                        Sign Out
                                                    </PBox>
                                                ),
                                                key: "signout",
                                            },
                                        ],
                                    }}
                                    trigger={["click"]}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                                    </svg>
                                </Dropdown>
                            </PBox>
                        )}
                        <Link to="/favorite">
                            <PBox className="shopping-cart">
                                <Badge count={this.props.saveProducts?.length}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M178,32c-20.65,0-38.73,8.88-50,23.89C116.73,40.88,98.65,32,78,32A62.07,62.07,0,0,0,16,94c0,70,103.79,126.66,108.21,129a8,8,0,0,0,7.58,0C136.21,220.66,240,164,240,94A62.07,62.07,0,0,0,178,32ZM128,206.8C109.74,196.16,32,147.69,32,94A46.06,46.06,0,0,1,78,48c19.45,0,35.78,10.36,42.6,27a8,8,0,0,0,14.8,0c6.82-16.67,23.15-27,42.6-27a46.06,46.06,0,0,1,46,46C224,147.61,146.24,196.15,128,206.8Z"></path>
                                    </svg>
                                </Badge>
                            </PBox>
                        </Link>
                    </PBox>
                </Sticky>
            </PSection>
        );
    }
}

const styles = {
    zIndex: "100",
    "& .header": {
        backgroundColor: "$colors$lightBackground",
        paddingTop: "2px",
        paddingBottom: "2px",
        borderBottom: "1px solid $colors$borderColor",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "60px",
        paddingRight: "60px",
    },
    "& .search-bar": {
        display: "flex",
        flexGrow: "1",
        marginLeft: "40px",
        marginRight: "40px",
        "& input": {
            width: "100%",
            height: "40px",
            border: "1px solid $colors$borderColor",
            borderTopLeftRadius: "24px",
            borderBottomLeftRadius: "24px",
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            borderRight: "0",
            outline: "none",
            paddingLeft: "20px",
            transition: "all 0.2s ease",
            "&:focus": {
                border: "2px solid black",
                "& + button": {
                    backgroundColor: "black",
                    borderColor: "black",
                    "& svg": { fill: "white" },
                },
            },
        },
        "& button": {
            border: "1px solid $colors$borderColor",
            backgroundColor: "#fff",
            borderLeft: "0",
            borderTopRightRadius: "24px",
            borderBottomRightRadius: "24px",
            paddingRight: "12px",
            paddingLeft: "12px",
            transition: "all 0.2s ease",
            "& svg": { fill: "$colors$borderColor" },
            "&:hover": { cursor: "pointer" },
        },
    },
    "& .sign-in": {
        marginRight: "10px",
        textDecoration: "none",
        backgroundColor: "$colors$secondary",
    },
    "& .start-selling": {
        marginRight: "10px",
        textDecoration: "none",
        backgroundColor: "$colors$tertiary",
    },
    "& .shopping-cart": {
        cursor: "pointer",
        margin: "0 6px",
        "& svg": { fill: "$colors$text" },
        "& svg:hover": { fill: "$colors$link" },
    },
    "& .shop-dashboard": {
        cursor: "pointer",
        margin: "0 6px",
        "& svg": { fill: "$colors$text" },
        "& svg:hover": { fill: "$colors$link" },
    },
    "& .account": {
        cursor: "pointer",
        margin: "0 6px",
        "& svg": { fill: "$colors$text" },
        "& svg:hover": { fill: "$colors$link" },
    },
};

function mapStateToProps(state) {
    return {
        userAuth: state.authReducer?.userAuth,
        saveProducts: state.saveReducer?.manageSave?.saveProducts,
    };
}

export default connect(mapStateToProps, { userSignOut })(withRouter(Header));
