import { combineReducers } from "redux";

const fetchMerchantsInfo = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MERCHANTS_INFO_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                info: action.payload,
            };
        case "FETCH_MERCHANTS_INFO_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                info: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({ fetchMerchantsInfo });
