import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PContainer, PSection, PBox, PH1, PText } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Pagination } from "antd";
import { fetchProductsInfo } from "../../../actions/product";

class ProductManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 1,
            pageSize: 10,
        };
    }

    async componentDidMount() {
        await this.props.fetchProductsInfo(
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="product-management">
                            <PH1>Product Management</PH1>
                            {this.props.products?.map((product, idx) => {
                                return (
                                    <PBox className="product-management-product-item">
                                        <PText>
                                            <b>
                                                {product.id}.{" "}
                                                {product.product_name}
                                            </b>{" "}
                                        </PText>
                                        <PText>
                                            <b>Category: </b>
                                            {product.category.category_name}
                                        </PText>
                                        <PText>
                                            <b>Average Rating: </b>
                                            {
                                                product.product_stat
                                                    .average_rating
                                            }
                                        </PText>
                                        <PText>
                                            <b>Number of Ratings: </b>
                                            {
                                                product.product_stat
                                                    .number_of_ratings
                                            }
                                        </PText>
                                        <PText>
                                            <b>Revenue: </b>$
                                            {product.product_stat.revenue}
                                        </PText>
                                        <PText>
                                            <b>Shop Name: </b>
                                            {product.shop_name}
                                        </PText>
                                        <PText>
                                            <b>Status: </b>
                                            {product.status}
                                        </PText>
                                    </PBox>
                                );
                            })}
                            <Pagination
                                pageSize={this.state.pageSize}
                                current={this.state.page}
                                total={this.props.total}
                                onChange={async (pageNumber) => {
                                    await this.setState({ page: pageNumber });
                                    await this.props.fetchProductsInfo(
                                        (this.state.page - 1) *
                                            this.state.pageSize,
                                        this.state.pageSize
                                    );
                                }}
                            />
                        </PBox>
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .product-management-product-item": {
        borderBottom: "1px solid $colors$borderColor",
        padding: "20px",
    },
};

function mapStateToProps(state) {
    return {
        products: state.productReducer?.fetchProductsInfo?.info?.productList,
        total: state.productReducer?.fetchProductsInfo?.info?.totalProducts,
    };
}

export default connect(mapStateToProps, { fetchProductsInfo })(
    withRouter(ProductManagement)
);
