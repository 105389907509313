import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import {
    PContainer,
    PH1,
    PSection,
    PText,
    PBox,
    PHighlightButton,
    PInput,
    PLink,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import {
    deleteCategory,
    fetchCategoriesInfo,
    addCategory,
    editCategory,
} from "../../../actions/category";
import { Input, Modal } from "antd";

class CategoryManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            product_counts: [],
            newCategory: "",
            isModalOpen: [],
            editCategory: "",
        };
    }

    async componentDidMount() {
        await this.props.fetchCategoriesInfo();
        for (var i = 0; i < this.props.categories?.length; i++) {
            var has_count = false;
            for (var j = 0; j < this.props.products?.length; j++) {
                if (
                    this.props.products[j].category_id ===
                    this.props.categories[i].id
                ) {
                    has_count = true;
                    this.setState({
                        product_counts: this.state.product_counts.concat([
                            this.props.products[j].product_count,
                        ]),
                    });
                }
            }
            if (!has_count) {
                this.setState({
                    product_counts: this.state.product_counts.concat([0]),
                });
            }
            this.setState({
                isModalOpen: this.state.isModalOpen.concat([false]),
            });
        }
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="category-management">
                            <PH1>Category Management</PH1>
                            <Input
                                rows={1}
                                placeholder="New Category Name"
                                id="category"
                                value={this.state.newCategory}
                                onChange={(e) => {
                                    this.setState({
                                        newCategory: e.target.value,
                                    });
                                }}
                            />
                            <PHighlightButton
                                className="new-category-button"
                                onClick={async () => {
                                    await this.props.addCategory(
                                        this.state.newCategory
                                    );
                                    await this.props.fetchCategoriesInfo();
                                    this.setState({
                                        product_counts:
                                            this.state.product_counts.concat([
                                                0,
                                            ]),
                                        newCategory: "",
                                    });
                                }}
                            >
                                Add Category
                            </PHighlightButton>
                            {this.props.categories?.map((cat, idx) => {
                                return (
                                    <PBox className="category-item">
                                        <PText>
                                            Id: <b>{cat.id}</b>, Category:{" "}
                                            <b>{cat.category_name}</b>, Product
                                            Count:{" "}
                                            <b>
                                                {this.state.product_counts[idx]}
                                            </b>
                                        </PText>
                                        <PLink
                                            onClick={() => {
                                                this.setState({
                                                    isModalOpen: [
                                                        ...this.state.isModalOpen.slice(
                                                            0,
                                                            idx
                                                        ),
                                                        true,
                                                        ...this.state.isModalOpen.slice(
                                                            idx + 1
                                                        ),
                                                    ],
                                                    editCategory:
                                                        cat.category_name,
                                                });
                                            }}
                                        >
                                            Edit
                                        </PLink>
                                        <Modal
                                            title="Update Category Name"
                                            open={this.state.isModalOpen[idx]}
                                            onOk={async () => {
                                                await this.props.editCategory(
                                                    cat.id,
                                                    this.state.editCategory
                                                );
                                                this.setState({
                                                    editCategory: "",
                                                });
                                                this.setState({
                                                    isModalOpen: [
                                                        ...this.state.isModalOpen.slice(
                                                            0,
                                                            idx
                                                        ),
                                                        false,
                                                        ...this.state.isModalOpen.slice(
                                                            idx + 1
                                                        ),
                                                    ],
                                                });
                                                await this.props.fetchCategoriesInfo();
                                            }}
                                            onCancel={() => {
                                                this.setState({
                                                    isModalOpen: [
                                                        ...this.state.isModalOpen.slice(
                                                            0,
                                                            idx
                                                        ),
                                                        false,
                                                        ...this.state.isModalOpen.slice(
                                                            idx + 1
                                                        ),
                                                    ],
                                                    editCategory: "",
                                                });
                                            }}
                                        >
                                            <Input
                                                placeholder={cat.category_name}
                                                id="edit_cat"
                                                defaultValue={cat.category_name}
                                                onChange={(e) => {
                                                    this.setState({
                                                        editCategory:
                                                            e.target.value,
                                                    });
                                                }}
                                            />
                                        </Modal>
                                        {this.state.product_counts[idx] ===
                                            0 && (
                                            <PLink
                                                onClick={async () => {
                                                    await this.props.deleteCategory(
                                                        cat.id
                                                    );
                                                    await this.props.fetchCategoriesInfo();
                                                }}
                                            >
                                                Delete
                                            </PLink>
                                        )}
                                    </PBox>
                                );
                            })}
                        </PBox>
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .new-category-button": {
        backgroundColor: "$colors$primary",
    },
    "& .category-item": {
        display: "flex",
        alignItems: "center",
        "& a": {
            marginLeft: "20px",
            cursor: "pointer",
            color: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {
        categories:
            state.categoryReducer?.fetchCategoriesInfo?.info?.categories,
        products: state.categoryReducer?.fetchCategoriesInfo?.info?.products,
    };
}

export default connect(mapStateToProps, {
    fetchCategoriesInfo,
    addCategory,
    deleteCategory,
    editCategory,
})(withRouter(CategoryManagement));
