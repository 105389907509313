import { message } from "antd";
import axios from "axios";

export const fetchCategoriesInfo = () => async (dispatch) => {
    await axios
        .get("/api/categories/info")
        .then(async (response) => {
            dispatch({
                type: "FETCH_CATEGORIES_INFO_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const addCategory = (category) => async (dispatch) => {
    await axios
        .post(`/api/categories/add/${category}`)
        .then(async (response) => {
            message.success(response?.data?.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const editCategory = (id, category) => async (dispatch) => {
    await axios
        .post(`/api/categories/edit/${id}/${category}`)
        .then(async (response) => {
            message.success(response?.data?.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const deleteCategory = (id) => async (dispatch) => {
    await axios
        .delete(`/api/categories/${id}`)
        .then(async (response) => {
            message.success(response?.data?.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};
