import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PHighlightButton,
    PBox,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Input, Button, Form } from "antd";
import { userSignUp } from "../../../actions/auth";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {}

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = (values) => {
        console.log("Received values of form: ", values);

        this.props.userSignUp(values, this.props.history);
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="sign-up">
                    <PBox className="sign-up-title">
                        <PH2>Register Account</PH2>
                        <PText>Get your free Phair account now.</PText>
                    </PBox>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 0,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: "100%",
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="user_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your username.",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Username"
                                prefix={<UserOutlined />}
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    message: "${label} is not a valid email.",
                                },
                                {
                                    required: true,
                                    message: "Please enter your email.",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Email"
                                prefix={<MailOutlined />}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your password.",
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder="Password"
                                prefix={<LockOutlined />}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirm_password"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Please confirm your password.",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue("password") === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "The new password that you entered do not match."
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder="Confirm Password"
                                prefix={<LockOutlined />}
                            />
                        </Form.Item>
                        <PBox className="sign-up-term-of-use">
                            <PText>
                                By registering you agree to the Pixa Market{" "}
                                <PLink href="#">Terms of Use</PLink>
                            </PText>
                        </PBox>
                        <Form.Item
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <PHighlightButton
                                type="primary"
                                htmlType="submit"
                                size="large"
                                className="sign-up-button"
                            >
                                Register
                            </PHighlightButton>
                        </Form.Item>
                    </Form>
                    <PText>- Or you can join with -</PText>
                    <PBox className="sign-up-button-group">
                        <PBox className="sign-up-facebook">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M223.3,169.59a8.07,8.07,0,0,0-2.8-3.4C203.53,154.53,200,134.64,200,120c0-17.67,13.47-33.06,21.5-40.67a8,8,0,0,0,0-11.62C208.82,55.74,187.82,48,168,48a72.2,72.2,0,0,0-40,12.13,71.56,71.56,0,0,0-90.71,9.09A74.63,74.63,0,0,0,16,123.4a127.06,127.06,0,0,0,40.14,89.73A39.8,39.8,0,0,0,83.59,224h87.68a39.84,39.84,0,0,0,29.12-12.57,125,125,0,0,0,17.82-24.6C225.23,174,224.33,172,223.3,169.59Zm-34.63,30.94a23.76,23.76,0,0,1-17.4,7.47H83.59a23.82,23.82,0,0,1-16.44-6.51A111.14,111.14,0,0,1,32,123,58.5,58.5,0,0,1,48.65,80.47,54.81,54.81,0,0,1,88,64h.78A55.45,55.45,0,0,1,123,76.28a8,8,0,0,0,10,0A55.44,55.44,0,0,1,168,64a70.64,70.64,0,0,1,36,10.35c-13,14.52-20,30.47-20,45.65,0,23.77,7.64,42.73,22.18,55.3A105.82,105.82,0,0,1,188.67,200.53ZM128.23,30A40,40,0,0,1,167,0h1a8,8,0,0,1,0,16h-1a24,24,0,0,0-23.24,18,8,8,0,1,1-15.5-4Z"></path>
                            </svg>
                        </PBox>
                        <PBox className="sign-up-google">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M224,128a96,96,0,1,1-21.95-61.09,8,8,0,1,1-12.33,10.18A80,80,0,1,0,207.6,136H128a8,8,0,0,1,0-16h88A8,8,0,0,1,224,128Z"></path>
                            </svg>
                        </PBox>
                        <PBox className="sign-up-instagram">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"></path>
                            </svg>
                        </PBox>
                    </PBox>
                    <PText>
                        Already have an account?{" "}
                        <PLink href="/sign-in">Login</PLink>
                    </PText>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
        },
    },
    "& .sign-up-button": {
        color: "#fff",
        backgroundColor: "$colors$primary",
        width: "100%",
    },
    "& .sign-up-title": {
        marginBottom: "40px",
    },
    "& .sign-up-term-of-use": {
        marginBottom: "20px",
    },
    "& .sign-up-button-group": {
        display: "grid",
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        margin: "20px 0",
        width: "100%",
        gridGap: "10px",
    },
    "& .sign-up-facebook": {
        gridColumn: "1 / 2",
        backgroundColor: "#e4e1f5",
        textAlign: "center",
        padding: "6px 0",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            fill: "#776acf",
        },
        "&:hover": {
            backgroundColor: "#776acf",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .sign-up-google": {
        gridColumn: "2 / 3",
        backgroundColor: "rgba(225,102,93,.1)",
        textAlign: "center",
        padding: "6px 0",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            fill: "#e1665d",
        },
        "&:hover": {
            backgroundColor: "#e1665d",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .sign-up-instagram": {
        gridColumn: "3 / 4",
        backgroundColor: "#dff6fd",
        textAlign: "center",
        padding: "6px 0",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            fill: "#5fd0f3",
        },
        "&:hover": {
            backgroundColor: "#5fd0f3",
            "& svg": {
                fill: "#fff",
            },
        },
    },
};

export default connect(null, { userSignUp })(withRouter(SignUp));
