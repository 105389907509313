import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import {
    PContainer,
    PH1,
    PSection,
    PBox,
    PImg,
    PText,
    PSpan,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { fetchPendingApprovalProducts } from "../../../actions/product";

class PendingApprovalList extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props.fetchPendingApprovalProducts().then(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="pending-approval-list-title">
                            <PH1>Pending Approval Products List</PH1>
                        </PBox>
                        {this.props.productList?.map((product, idx) => {
                            return (
                                <PBox className="pending-approval-list-item">
                                    <PBox className="pending-approval-list-item-thumbnail">
                                        <PImg
                                            src={
                                                product.product_cover_image.url
                                            }
                                        />
                                    </PBox>
                                    <PBox className="pending-approval-list-item-desc">
                                        <PText>
                                            <PSpan className="pending-approval-list-item-subtitle">
                                                Product Name{" "}
                                            </PSpan>
                                            <PSpan>
                                                {product.product_name}
                                            </PSpan>
                                        </PText>
                                        <PText>
                                            <PSpan className="pending-approval-list-item-subtitle">
                                                Update Date{" "}
                                            </PSpan>
                                            <PSpan>
                                                {new Date(
                                                    product.updatedAt
                                                ).toDateString()}
                                            </PSpan>
                                            {", "}
                                            <PSpan>
                                                {new Date(
                                                    product.updatedAt
                                                ).toTimeString()}
                                            </PSpan>
                                        </PText>
                                        <PText>
                                            <PSpan className="pending-approval-list-item-subtitle">
                                                Price{" "}
                                            </PSpan>
                                            <PSpan>${product.price}</PSpan>
                                        </PText>
                                    </PBox>
                                    <PBox>
                                        <Link
                                            className="pending-approval-list-link"
                                            to={`/approve-product/${product.uuid}`}
                                        >
                                            Product Details
                                        </Link>
                                    </PBox>
                                </PBox>
                            );
                        })}
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .pending-approval-list-title": {
        borderBottom: "1px solid $colors$borderColor",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& h1": {
            fontWeight: "500",
        },
    },
    "& .pending-approval-list-item": {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        margin: "0px 20px",
        padding: "40px 0",
        fontSize: "15px",
        borderBottom: "1px solid $colors$borderColor",
        "& .pending-approval-list-item-thumbnail": {
            width: "80px",
            height: "80px",
            margin: "0 20px 0 0",
            "& img": {
                width: "80px",
                height: "80px",
                objectFit: "cover",
            },
        },
        "& .pending-approval-list-item-desc": {
            flexGrow: "1",
            color: "$colors$text",
            "& p": {
                fontWeight: "300",
                padding: 0,
                margin: "0 0 10px 0",
            },
        },
        "& .pending-approval-list-item-subtitle": {
            fontWeight: "500",
        },
        "& .pending-approval-list-link": {
            padding: "10px 20px",
            border: "1px solid $colors$text",
            cursor: "pointer",
            textDecoration: "none",
            color: "$colors$text",
            "&:hover": {
                backgroundColor: "$colors$primary",
                color: "#fff",
                borderColor: "#fff",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        productList:
            state.productReducer?.fetchPendingApprovalProductList?.productList,
    };
}

export default connect(mapStateToProps, { fetchPendingApprovalProducts })(
    withRouter(PendingApprovalList)
);
