import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PH2, PLink, PText, PBox } from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { userSessionExpired } from "../../../actions/auth";

class SessionExpired extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.userSessionExpired();
    }

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="session-expired">
                    <PBox className="session-expired-title">
                        <PBox>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"></path>
                            </svg>
                        </PBox>
                        <PH2>Session Expired</PH2>
                        <PText>
                            Your session has expired, please{" "}
                            <a href="/sign-in">sign in</a> again.
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    minHeight: "400px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        textDecoration: "none",
        "&:hover": {
            color: "#000",
            cursor: "pointer",
        },
    },
    "& .session-expired-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "100px",
            fill: "$colors$primary",
        },
    },
};

export default connect(null, { userSessionExpired })(
    withRouter(SessionExpired)
);
