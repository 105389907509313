import { Link } from "react-router-dom";
import { PBox, PSpan } from "../theme/BaseTheme";

export const Logo = () => {
    return (
        <PBox css={styles} className="logo">
            <Link to="/">
                <PSpan className="logo-p">P</PSpan>
                <PSpan className="logo-h">h</PSpan>
                <PSpan className="logo-a">a</PSpan>
                <PSpan className="logo-i">i</PSpan>
                <PSpan className="logo-r">r</PSpan>
            </Link>
        </PBox>
    );
};

const styles = {
    fontFamily: "Mansalva",
    "& a": {
        textDecoration: "none",
        color: "$colors$text",
        fontWeight: "700",
        fontSize: "36px",
    },
    "& .logo-p": {
        //color: "$colors$quinary",
    },
    "& .logo-h": {
        //color: "$colors$secondary",
    },
    "& .logo-a": {
        //color: "$colors$quaternary",
    },
    "& .logo-i": {
        //color: "$colors$primary",
    },
    "& .logo-r": {
        //color: "$colors$tertiary",
    },
};
