import { Component, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
    Rate,
    Input,
    Form,
    Space,
    Button,
    Popconfirm,
    Modal,
    Checkbox,
    Radio,
} from "antd";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PH3,
    PImg,
    PLink,
    PText,
    PHr,
    PSpan,
} from "../../theme/BaseTheme";
import { Loader } from "../../components/Loader";
import { Layout } from "./Layout";
import { fetchProductDetails } from "../../../actions/product";
import { approveProduct } from "../../../actions/product";

const ApproveForm = (props) => {
    const [approve, setApprove] = useState(null);
    const [rejectReason, setRejectReason] = useState("");
    const [feedback, setFeedback] = useState("");
    const onApproveFinish = (values) => {
        props.approveProduct({
            product_uuid: props.product_uuid,
            merchant_id: props.merchant_id,
            approve: approve,
            rejectReason: rejectReason,
            feedback: feedback,
        });
    };
    return (
        <Form name="approve_form" layout="vertical" onFinish={onApproveFinish}>
            <Form.Item>
                <Radio.Group
                    onChange={(e) => {
                        setApprove(e.target.value);
                    }}
                    value={approve}
                >
                    <Radio value={true}>Approve</Radio>
                    <Radio value={false}>Reject</Radio>
                </Radio.Group>
            </Form.Item>
            {approve === false && (
                <Form.Item>
                    <Checkbox.Group
                        options={["product and description do not match"]}
                        onChange={(checkedValues) => {
                            setRejectReason(checkedValues);
                        }}
                    />
                </Form.Item>
            )}
            <Form.Item>
                <Input.TextArea
                    rows={4}
                    placeholder="Feedback"
                    id="approve-feedback"
                    value={feedback}
                    onChange={(e) => {
                        setFeedback(e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item className="approve-form-buttons">
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button htmlType="reset">Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

class ApproveProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.props
            .fetchProductDetails(this.props.match.params.product_uuid)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="approve-product">
                        <PContainer>
                            <PBox className="approve-product-title">
                                <PH1>Product Details</PH1>
                            </PBox>
                            <PBox className="approve-product-content">
                                <PBox className="approve-product-details">
                                    <PText>
                                        <b>Product Name: </b>
                                        {this.props.product.product_name}
                                    </PText>
                                    <PText>
                                        <b>Product UUID: </b>
                                        {this.props.product.uuid}
                                    </PText>
                                    <PText>
                                        <b>Product price: </b>$
                                        {this.props.product.price}
                                    </PText>
                                    <PText>
                                        <b>Download Product: </b>
                                        {this.props.product.product_files.map(
                                            (file) => {
                                                return (
                                                    <PSpan>{file.url} </PSpan>
                                                );
                                            }
                                        )}
                                    </PText>
                                    <PText>
                                        <b>Category: </b>
                                        {this.props.product.category_name}
                                    </PText>
                                    <PText>
                                        <b>Product Tags: </b>
                                        <PSpan>
                                            {this.props.product.tags.map(
                                                (tag) => {
                                                    return <>{tag.tag_name} </>;
                                                }
                                            )}
                                        </PSpan>
                                    </PText>
                                    <PText>
                                        <b>Merchant: </b>
                                        {this.props.product.merchant.shop_name}
                                    </PText>
                                    <PText>
                                        <b>Product Highlights: </b>
                                        {this.props.product.product_highlights}
                                    </PText>
                                    <PText>
                                        <b>Product Description: </b>
                                        {this.props.product.product_description}
                                    </PText>
                                    <PText>
                                        <b>Terms: </b>
                                        {this.props.product.product_delivery}
                                    </PText>
                                    <PText>
                                        <b>Cover Image: </b>
                                    </PText>
                                    <PImg
                                        height="200px"
                                        src={
                                            this.props.product
                                                .product_cover_image.url
                                        }
                                    />
                                    <PText>
                                        <b>Product Images: </b>
                                    </PText>
                                    {this.props.product.product_images.map(
                                        (image) => {
                                            return (
                                                <PImg
                                                    height="200px"
                                                    style={{
                                                        marginRight: "20px",
                                                    }}
                                                    src={image.url}
                                                />
                                            );
                                        }
                                    )}
                                </PBox>
                                <PBox className="approve-product-form">
                                    <ApproveForm
                                        approveProduct={
                                            this.props.approveProduct
                                        }
                                        product_uuid={this.props.product.uuid}
                                        merchant_id={
                                            this.props.product.merchant_id
                                        }
                                    />
                                </PBox>
                            </PBox>
                        </PContainer>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    "& .approve-product": {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    "& .approve-product-content": {
        display: "flex",
    },
    "& .approve-product-form": {
        minWidth: "400px",
    },
    "& .approve-product-title": {
        borderBottom: "1px solid $colors$borderColor",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0 0 40px 0",
        "& h1": {
            fontWeight: "500",
        },
        "& a": {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "$colors$text",
            "& svg": {
                marginRight: "5px",
                fill: "$colors$text",
            },
            "&:hover": {
                cursor: "pointer",
                color: "$colors$primary",
                "& svg": { fill: "$colors$primary" },
            },
        },
    },
    "& .approve-product-section-title-quick-buttons": {
        "& a": {
            textDecoration: "none",
        },
    },
    "& .approve-product-title-quick-buttons": {
        display: "flex",
        "& a": {
            margin: "0 10px",
        },
    },
    "& .approve-product-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        margin: "20px auto",
        fontSize: "15px",
        "@bp3": {
            flexDirection: "row",
        },
    },
    "& .approve-product-item-product": {
        width: "100%",
        border: "1px solid $colors$borderColor",
        borderRadius: "12px",
        padding: "0 20px 20px 20px",
        margin: "20px",
    },
    "& .approve-product-item-product-content": {
        display: "flex",
        flexDirection: "row",
    },
    "& .approve-product-item-thumbnail": {
        width: "80px",
        height: "80px",
        margin: "0 20px 0 0",
        "& img": {
            width: "80px",
            height: "80px",
            objectFit: "cover",
        },
    },
    "& .approve-product-item-desc-price": {
        flexGrow: "1",
        color: "$colors$text",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        fontSize: "16px",
        "& .approve-product-item-desc": {
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            marginBottom: "4px",
        },
        "& .approve-product-item-price": {},
        "& p": { margin: "0" },
    },
    "& .approve-product-item-review-form": {
        padding: "0 20px 20px 20px",
        width: `calc(100% - 40px)`,
        border: "1px solid $colors$borderColor",
        borderRadius: "12px",
        margin: "20px",
    },
    "& .approve-product-payment-total": {
        margin: "20px",
    },
    "& .form-hidden-item": {
        display: "none",
    },
    "& .approve-product-section-title": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        borderBottom: "1px solid $colors$borderColor",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "10px",
        },
        "& a": {
            color: "$colors$text",
            "&:hover": {
                cursor: "pointer",
                color: "$colors$primary",
            },
        },
    },
    "& .approve-product-section-title-left": {
        display: "flex",
        alignItems: "center",
    },
    "& .approve-product-item-form-buttons": {
        marginTop: "20px",
    },
};

function mapStateToProps(state) {
    return {
        product: state.productReducer?.fetchProductDetails?.product,
    };
}

export default connect(mapStateToProps, {
    fetchProductDetails,
    approveProduct,
})(withRouter(ApproveProduct));
