import { combineReducers } from "redux";
import productReducer from "./productReducer";
import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import merchantReducer from "./merchantReducer";
import orderReducer from "./orderReducer";

const reducers = {
    productReducer,
    authReducer,
    categoryReducer,
    orderReducer,
    merchantReducer,
};

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT_SUCCESS") {
        state = {};
    }
    return appReducer(state, action);
};

export default rootReducer;
