import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { userSignOut } from "../../../actions/auth";

class SignOut extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles} className="sign-out">
                    <PBox className="sign-out-title">
                        <PBox>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                            </svg>
                        </PBox>
                        <PH2>You are Logged Out</PH2>
                        <PText>Thank you for using Phair!</PText>
                    </PBox>

                    <PHighlightButton
                        type="primary"
                        size="large"
                        className="sign-out-button"
                        style={{}}
                        onClick={() => {
                            this.props.history.push("/");
                        }}
                    >
                        Home
                    </PHighlightButton>

                    <PText>
                        Sign in to continue.{" "}
                        <PLink href="/sign-in">Login</PLink>
                    </PText>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    marginBottom: "40px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    minHeight: "400px",
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
        },
    },
    "& .sign-out-button": {
        backgroundColor: "$colors$primary",
        width: "100%",
        margin: "20px 0",
        color: "#fff",
    },
    "& .sign-out-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "100px",
            fill: "$colors$primary",
        },
    },
};

export default connect(null, { userSignOut })(withRouter(SignOut));
