import { PBox, PContainer, PLink, PSection } from "../theme/BaseTheme";

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <PSection css={styles}>
            <PBox className="footer">
                <PContainer>
                    <PBox className="copyright">
                        {year} Phair Labs @ All Rights Reserved
                    </PBox>
                    <PBox className="footer-links">
                        <PLink href="#">About</PLink>
                        <PLink href="/terms">Terms</PLink>
                        <PLink href="/licenses">Licenses</PLink>
                        <PLink href="/privacy">Privacy</PLink>
                        <PLink href="/contact">Contact</PLink>
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .footer": {
        backgroundColor: "$colors$text",
        paddingTop: "40px",
        paddingBottom: "40px",
        color: "#fff",
    },
    "& .footer > div": { display: "flex", justifyContent: "space-between" },
    "& .footer-links > a": {
        paddingLeft: "10px",
        paddingRight: "10px",
        color: "#fff",
        textDecoration: "none",
        fontSize: "1rem",
        "&:hover": { color: "$colors$link" },
    },
    "& .copyright": { color: "#fff", fontSize: "1rem" },
};
