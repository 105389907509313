import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PContainer, PSection, PBox, PH1, PText } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { fetchOrdersInfo } from "../../../actions/order";
import { Pagination } from "antd";

class OrderManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 1,
            pageSize: 10,
        };
    }

    async componentDidMount() {
        await this.props.fetchOrdersInfo(this.state.page, this.state.pageSize);
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PContainer>
                        <PBox className="order-management">
                            <PH1>Order Management</PH1>
                            {this.props.orders?.map((order, idx) => {
                                return (
                                    <PBox className="order-management-order-item">
                                        <PText>
                                            Order: {order.id}
                                            {", "}
                                            {order.order_number}
                                        </PText>
                                        <PText>
                                            Customer:
                                            {order.customer_name}
                                            {", "}
                                            {order.customer_email}
                                            {", "}
                                            {order.customer_address?.line1}{" "}
                                            {order.customer_address?.line2}{" "}
                                            {order.customer_address?.city}{" "}
                                            {order.customer_address?.state}{" "}
                                            {order.customer_address?.country}{" "}
                                            {
                                                order.customer_address
                                                    ?.postal_code
                                            }{" "}
                                        </PText>
                                        <PText>
                                            Subtotal: {order.subtotal},
                                            Discount: {order.discount}, Tax:{" "}
                                            {order.tax}, Transaction Fee:{" "}
                                            {order.transaction_fee}, Total:{" "}
                                            {order.total}
                                        </PText>
                                    </PBox>
                                );
                            })}
                            <Pagination
                                pageSize={this.state.pageSize}
                                current={this.state.page}
                                total={this.props.total}
                                onChange={async (pageNumber) => {
                                    await this.setState({ page: pageNumber });
                                    await this.props.fetchOrdersInfo(
                                        this.state.page,
                                        this.state.pageSize
                                    );
                                }}
                            />
                        </PBox>
                    </PContainer>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    marginTop: "20px",
    padding: "20px",
    "& .order-management-order-item": {
        borderBottom: "1px solid $colors$borderColor",
        padding: "20px",
    },
};

function mapStateToProps(state) {
    return {
        orders: state.orderReducer?.fetchOrdersInfo?.info?.orders,
        total: state.orderReducer?.fetchOrdersInfo?.info?.total,
    };
}

export default connect(mapStateToProps, { fetchOrdersInfo })(
    withRouter(OrderManagement)
);
