import { message } from "antd";
import axios from "axios";

export const fetchOrdersInfo = (page, page_size) => async (dispatch) => {
    await axios
        .get(`/api/orders/info/${page}/${page_size}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_ORDERS_INFO_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};
