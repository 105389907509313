import { message } from "antd";
import axios from "axios";

export const fetchPendingApprovalProducts = () => async (dispatch) => {
    await axios
        .get("/api/products/all_pending_approval")
        .then(async (response) => {
            dispatch({
                type: "FETCH_PENDING_APPROVAL_PRODUCT_LIST_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchProductDetails = (product_uuid) => async (dispatch) => {
    await axios
        .get(`/api/products/${product_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_PRODUCT_DETAILS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const approveProduct = (values) => async (dispatch) => {
    await axios
        .post(`/api/products/approve`, values)
        .then(async (response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchProductsInfo = (page, page_size) => async (dispatch) => {
    await axios
        .get(`/api/products/info/${page}/${page_size}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_PRODUCTS_INFO_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};
